  import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { Plugins } from '@capacitor/core';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Platform, AlertController, LoadingController } from '@ionic/angular';
import { CourseService } from './../api/course.service';
import { Location } from '@angular/common';

const { Storage } = Plugins;

const TOKEN_KEY = 'access_token'
const SHOW_COURSES = 'show_courses';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = null;
  user = null;

  constructor(private http: HttpClient, 
              private alertController: AlertController, 
              private platform: Platform,
              private api: CourseService,
              private loadingController: LoadingController,
              private location: Location,
              private helper: JwtHelperService) {
    this.platform.ready().then(() => {
      this.loadToken();
    });
    
  }


  async loadToken() {
    this.user = await Storage.get({ key: 'user' });

    Storage.get({ key: TOKEN_KEY }).then(token => {
     
      if (token && token.value) {
        let decoded = this.helper.decodeToken(token.value);
        let isExpired = this.helper.isTokenExpired(token.value);
      
        if (!isExpired) { 
          this.token = token.value;
          this.isAuthenticated.next(true);
     
          Storage.get({ key: 'user' }).then(data => {
            this.user = JSON.parse(data.value);
            console.log(this.user.email + ' already authenticated with token ' + this.token);
          });
                    
        } else {
          this.isAuthenticated.next(false);
          Storage.remove({ key: 'user' });
          console.log('APP: token expired');
        }  
      } else {
        this.isAuthenticated.next(false);
        Storage.remove({ key: 'user' });
        console.log('APP: token undefined');
      }
    }) 
  }


  login(credentials) {
    var params = 
      {
        "user": {
          "email": credentials.email,
          "password": credentials.password
        }
      };
    console.log("JWT Login to " + environment.api_url + '/login.json' );   
    return this.http.post(environment.api_url + '/login.json', params, { observe: 'response'})
    .pipe(
      tap(res => {
        var bearer = res.headers.get('authorization');
        Storage.set({key: TOKEN_KEY, value: bearer});

        
        if (this.helper.decodeToken(bearer) == null) {
          console.log('Authentication failed!');  
        } else {
          console.log('Authentication success');

          var body = res.body;
          this.user = res.body['user'];
          Storage.set({key: 'user', value: JSON.stringify(this.user) });
          console.log(this.user);
          Storage.set({key: SHOW_COURSES, value: this.user.courses});
          this.token = bearer;
          this.isAuthenticated.next(true); 
        }
      }),
      catchError(e => {
        alert("Controlla i dati inseriti e riprova.");
        throw new Error(e);
        console.log('Login error');
      })
    );
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Ci sei quasi!',
      subHeader: 'Conferma account',
      message: 'Controlla la tua email e conferma il tuo account per effettuare il login.',
      buttons: ['OK']
    });
    await alert.present();
  }


  signup(credentials) {
    var params = 
      {
        "account": {
          "email": credentials.email,
          "password": credentials.password,
          "password_confirmation": credentials.password,
          "first_name": credentials.first_name,
          "last_name": credentials.last_name,
          "phone": credentials.phone,
          "privacy_acceptance": credentials.privacy_acceptance      
        }
      };

    console.log("JWT Register to " + environment.api_url + '/accounts.json' );   
    return this.http.post(`${environment.api_url}/accounts.json`, params, { observe: 'response'})
    .pipe(
      tap(res => {
        console.log('Registration success'); 
        var body = res.body;
        console.log(body); 

        this.presentAlert();  
     }),
      catchError(e => {
        alert("Controlla i dati inseriti e riprova.");
        throw new Error(e);
        console.log(e);
      })
    );
  }  
 
  logout() {
    this.isAuthenticated.next(false);
    Storage.remove({key: TOKEN_KEY});
    Storage.remove({key: 'user'});
    Storage.remove({key: 'courses'});
    //Storage.clear();
  }

}
