import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Plugins } from '@capacitor/core';
import { map, tap, switchMap, catchError } from 'rxjs/operators';

const { Storage } = Plugins;

const TOKEN_KEY = 'access_token'

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  private url: string = environment.api_url;
  token: any;

  constructor(private loadingController: LoadingController,
              private alertController: AlertController, 
              private http: HttpClient,
  ) {}


  // API general functions
  //
  // 
  private postRequest(functionUrl: string = '', params: any = {}, token: string = '') {
    console.log('Post Request: ' + this.url + functionUrl + 'with token: ' + token);
    return this.http.post(this.url + functionUrl, params, this.httpOptions(token))
    .pipe(
      tap(res => {
        console.log(res);
      }),
      catchError(e => {
        alert("Error while get request to " + this.url + functionUrl );
        throw new Error(e);
        console.log("Error while get request to " + this.url + functionUrl);
      })
    );
  }




  private getRequest(functionUrl: string = '', token: string = '') {
    console.log('Get Request: ' + this.url + functionUrl + 'with token: ' + token);
    return this.http.get(this.url + functionUrl, this.httpOptions(token))
    .pipe(
      tap(res => {
        console.log(res);
      }),
      catchError(e => {
        alert("Error while get request to " + this.url + functionUrl );
        throw new Error(e);
        console.log("Error while get request to " + this.url + functionUrl);
      })
    );
  }

  private httpOptions(token: string) {
    let headers = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })  
    };
    return headers;
  }


  // API
  getCourseCategories(token) {
    return this.getRequest('/course_categories/list.json', token);
  }

  getCourses(token) {
    console.log('Token: ' + token);
    return this.getRequest('/courses/list.json', token);
  }

  getPreferred(token) {
    return this.getRequest('/courses/preferred.json', token);
  }

  setPreferred(id, token) {
    return this.getRequest('/courses/' + id + '/prefer.json', token);
  }

  getCourse(id, token) {  
    return this.getRequest('/courses/' + id + '.json', token);
  }

  getLesson(id, token) {
    return this.getRequest('/lessons/' + id + '.json', token);
  }

  viewLesson(id, token) {
    return this.getRequest('/lessons/' + id + '/view.json', token);
  }

  getPosts(token) {
    return this.getRequest('/posts/list.json', token);
  }

  getPost(id,token) {
    return this.getRequest('/posts/' + id + '.json', token);
  }

}
