import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthenticationService } from './api/authentication.service';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;
const SHOW_COURSES = 'show_courses';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  firstAccess: Boolean = true;
  currentPageTitle = 'Lezioni';

  appPages = [
    {
      title: 'Blog',
      url: '/tabs/blog',
      icon: 'newspaper'
    },
    {
      title: 'Info',
      url: '/tabs/tab2',
      icon: 'information-circle'
    },
    /*{
      title: 'Impostazioni',
      url: '/settings',
      icon: 'settings'
    }*/
  ];

  version = environment.version;

  logged = this.authService.isAuthenticated;

  constructor(private authService: AuthenticationService, 
              private router: Router) {
    this.initializeApp();
  }

  initializeApp() {
    console.log('initializing App...');
    Storage.get({ key: SHOW_COURSES }).then(res => {
      if (res && res.value == '1') {
        this.firstAccess = false;
        this.appPages = [
          {
            title: 'Corsi',
            url: '/tabs/tab1',
            icon: 'list'
          },
          {
            title: 'Blog',
            url: '/tabs/blog',
            icon: 'newspaper'
          },
          {
            title: 'Info',
            url: '/tabs/tab2',
            icon: 'information-circle'
          },
        ];
      }
    })
  }  

  async logout() {
    await this.authService.logout();
    this.router.navigateByUrl('/', { replaceUrl: true });
  }
}
